import styled from 'styled-components'
import _pick from 'lodash/pick'
import _omit from 'lodash/omit'

import FieldBase from './common/FieldBase'
import {
  labelFontSize,
  labelLineHeight,
  labelFontSizeMobile,
  labelLineHeightMobile,
  checkBoxSizeMobile,
  checkBoxSize
} from './common/vars'
import getInputProps from './common/getInputProps'
import Icon from 'components/Icon'
import * as colors from 'config/theme/colors'
import media from 'config/theme/media'
import useTranslate from 'hooks/useTranslate'
import Url from 'components/Url'

const Box = styled.span`
  overflow: hidden;
  box-sizing: border-box;
  width: ${checkBoxSizeMobile}px;
  height: ${checkBoxSizeMobile}px;
  border: 1px solid
    ${(p) => (p['aria-invalid'] ? colors.errorTextColor : colors.bodyFontColor)};
  opacity: ${({disabled}) => (disabled ? 0.3 : 1)};
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  & > span {
    opacity: 0;
  }
  input:checked ~ & {
    background-color: ${colors.black()};
  }
  input:checked ~ & > span {
    opacity: 1;
  }
  ${media.greaterThan('md')`
    width: ${checkBoxSize}px;
    height: ${checkBoxSize}px;
  `}
`
const CheckboxInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  left: -20px;
  :focus ~ ${Box} {
    outline: 3px solid #7aacfe;
  }
`
const Wrapper = styled.label`
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  user-select: none;
`

const Label = styled.span`
  display: block;
  font-size: ${labelFontSizeMobile}px;
  line-height: ${labelLineHeightMobile}px;
  flex-shrink: 1;
  padding-top: ${(checkBoxSizeMobile / 2) - (labelLineHeightMobile / 2)}px;
  ${media.greaterThan('md')`
    font-size: ${labelFontSize}px;
    line-height: ${labelLineHeight}px;
    padding-top: ${(checkBoxSize / 2) - (labelLineHeight / 2)}px;
  `}
`

const renderField = (props) => {
  const translate = useTranslate()
  const { label, name, labelLink } = props
  const inputProps = getInputProps(props)

  const { value, ...restInputProps } = inputProps
  const boxProps = _pick(inputProps, ['aria-invalid', 'disabled'])
  const checkboxProps = {
    ...(label ? _omit(restInputProps, ['aria-label', 'autoComplete']) : restInputProps),
    type: 'checkbox',
    id: name,
    name,
    checked: value && !!value.length
  }

  return (
    <Wrapper>
      <CheckboxInput {...checkboxProps} />
      <Box {...boxProps}>
        <Icon name={'tick'} width={24} height={19} as={'span'} />
      </Box>
      <Label>{labelLink ? <Url text={translate(label)} url={labelLink} target='_blank'/> : label }</Label>
    </Wrapper>
  )
}

const Checkbox = (props) => {
  return <FieldBase {...props} renderField={renderField} noLabel />
}

export default Checkbox
