import React from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'

import Form from 'components/Form'
import SubmitButton from 'components/Fields/SubmitButton'
import Select from 'components/Fields/Select'
import Checkbox from 'components/Fields/Checkbox'
import Password from 'components/Fields/Password'
import FormHeading from 'components/Fields/FormHeading'
import InfoList from 'components/Fields/InfoList'

import validators from '../../utils/validators'
import { useTranslate } from '../../hooks/useTranslate'
import { useShouldPrefillOccupationValue } from '../../hooks/organisationDetailsHooks'
import { useWebsiteConfig } from '../../hooks/configHooks'

import { getCountryList } from '../../services/countryCodeService'
import { getUserDetails } from '../../store/modules/tempUserDetails/selectors'

const countryCodeOptions = getCountryList()

const getValidationSchema = ({ variant, fieldOptions, prefillOccupationValue }) => {
  const websiteConfig = useWebsiteConfig()
  const { publicprofession, profession } = fieldOptions

  const defaultValidationSchema = validators.object({
    title: validators.requiredString(),
    firstName: validators.requiredString(),
    lastName: validators.requiredString(),
    email: validators.email(),
    occupation: !prefillOccupationValue && validators.requiredString(),
    hcpProfessionId: validators.requiredProfessionWhen('occupation', 'hcp', profession),
    publicProfessionId: validators.requiredProfessionWhen('occupation', 'public', publicprofession),
    houseNumberOrName: validators.maxLength(30),
    address: validators.maxLength(30),
    addressLineTwo: validators.maxLength(30),
    city: validators.optionalString().max(35),
    postalCode: validators.requiredString().max(15),
    privacy: validators.requiredString(),
    password: validators.optionalPassword(),
    confirmpassword: validators.optionalConfirmPassword('password'),
    telephone: validators.simpleTelephoneValidation(),
    telephoneCountryCode: validators.countryCodeValidation()
  })

  const registerValidationSchema = validators.object({
    title: validators.requiredString(),
    firstName: validators.requiredString(),
    lastName: validators.requiredString(),
    email: validators.email(),
    occupation: !prefillOccupationValue && validators.requiredString(),
    hcpProfessionId: validators.requiredProfessionWhen('occupation', 'hcp', profession),
    publicProfessionId: validators.requiredProfessionWhen('occupation', 'public', publicprofession),
    houseNumberOrName: validators.maxLength(30),
    address: validators.maxLength(30),
    addressLineTwo: validators.maxLength(30),
    city: validators.optionalString().max(35),
    postalCode: validators.requiredString().max(15),
    privacy: validators.requiredString(),
    password: validators.password(),
    confirmpassword: validators.confirmPassword('password'),
    telephone: validators.simpleTelephoneValidation(),
    telephoneCountryCode: validators.countryCodeValidation()
  })

  const accountValidationSchema = validators.object({
    title: validators.requiredString(),
    firstName: validators.requiredString(),
    lastName: validators.requiredString(),
    email: validators.email(),
    occupation: !prefillOccupationValue && validators.requiredString(),
    hcpProfessionId: validators.requiredProfessionWhen('occupation', 'hcp', profession),
    publicProfessionId: validators.requiredProfessionWhen('occupation', 'public', publicprofession),
    houseNumberOrName: validators.maxLength(30).nullable(),
    address: validators.maxLength(30).nullable(),
    addressLineOne: validators.maxLength(30).nullable(),
    addressLineTwo: validators.maxLength(30).nullable(),
    city: validators.optionalString().max(35).nullable(),
    postalCode: validators.requiredString().max(15),
    county: validators.optionalString().nullable(),
    telephone: validators.simpleTelephoneValidation().nullable(),
    telephoneCountryCode: validators.countryCodeValidation().nullable()
  })

  const adminAccountValidationSchema = validators.object({
    title: validators.requiredString(),
    firstName: validators.requiredString(),
    lastName: validators.requiredString(),
    email: validators.email(),
    address: validators.optionalString().max(30),
    addressLineOne: validators.optionalString().max(30),
    addressLineTwo: validators.optionalString().max(30),
    city: validators.optionalString().max(35),
    postalCode: validators.optionalString().max(15),
    county: validators.optionalString(),
    telephone: validators.simpleTelephoneValidation(),
    telephoneCountryCode: validators.countryCodeValidation()
  })

  const updatePasswordValidationSchema = validators.object({
    currentPassword: validators.password(),
    password: validators.password(),
    confirmpassword: validators.confirmPassword('password')
  })

  if (variant === 'register') {
    return registerValidationSchema
  }
  if (variant === 'account') {
    return accountValidationSchema
  }
  if (variant === 'updatePassword') {
    return updatePasswordValidationSchema
  }
  if (variant === 'adminAccount') {
    return adminAccountValidationSchema
  }
  return defaultValidationSchema
}

const getInitialValues = ({ variant, initialValues }) => {
  const websiteConfig = useWebsiteConfig()
  const defaultTelCountryCode = _.get(websiteConfig, 'defaultTelCountryCode')
  return {
    telephoneCountryCode: defaultTelCountryCode,
    ...initialValues
  }
}

const getSchema = ({ variant, fieldOptions, prefillOccupationValue, reportId, translate, translateExists, initialValues }) => {
  const websiteConfig = useWebsiteConfig()
  const tempUserDetails = useSelector(getUserDetails({ reportId }))

  let initialProfessionType
  if (reportId) {
    initialProfessionType = tempUserDetails.professionType
  } else if (initialValues) {
    switch (_.get(initialValues, 'professionType')) {
      case ('Health Care Professional'):
        initialProfessionType = 'hcp'
        break
      case ('Public'):
        initialProfessionType = 'public'
        break
      default:
        break
    }
  }

  const occupationField = {
    name: 'occupation',
    label: 'ABOUT_YOU_FORM_OCCUPATION',
    type: 'text',
    required: true,
    placeholder: 'Please select',
    component: Select,
    initialValue: initialProfessionType,
    options: [
      {
        label: 'Member of the public',
        value: 'public'
      },
      {
        label: 'Healthcare professional',
        value: 'hcp'
      }
    ],
    isVisible: () => !prefillOccupationValue
  }

  const professionField = {
    name: 'hcpProfessionId',
    label: 'ABOUT_YOU_FORM_HCP_OCCUPATION',
    type: 'text',
    required: true,
    component: Select,
    placeholder: 'Please select',
    options: fieldOptions.profession || [],
    initialValue: _.get(initialValues, 'professionId'),
    groupOptions: true,
    groupAttribute: 'professionGroupId',
    parent: 'professionGroup',
    parentValue: 'id',
    parentLabel: 'name',
    isVisible: (values) => {
      return ((values.occupation === 'hcp' || values.occupation === 'Health Care Professional') && fieldOptions.profession && fieldOptions.profession.length > 1)
    }
  }

  const translateLabel = ({ prefix, option, translate, translateExists }) => {
    const snakeCaseLabel = prefix + _.snakeCase(option.label).toUpperCase()
    return { value: option.value, label: translateExists(snakeCaseLabel) ? translate(snakeCaseLabel) : option.label }
  }

  const publicProfessionField = {
    name: 'publicProfessionId',
    label: 'ABOUT_YOU_FORM_PUBLIC_OCCUPATION',
    type: 'text',
    required: true,
    component: Select,
    initialValue: _.get(initialValues, 'professionId'),
    placeholder: 'Please select',
    options: _.map(fieldOptions.publicprofession, (opt) => {
      const prefix = "ABOUT_YOU_FORM_PUBLIC_OCCUPATION_KEY_"
      const option = opt
      option.label = _.startsWith(opt.label, 'Carer') ? 'Carer' : option.label
      return translateLabel({ prefix: prefix, option: option, translate: translate, translateExists: translateExists })
    }).sort((a, b) => a.label.length - b.label.length),
    isVisible: (values) => {
      return ((values.occupation === 'public' || values.occupation === 'Public') && fieldOptions.publicprofession.length > 1)
    }
  }

  const titleField = {
    name: 'title',
    label: 'ABOUT_YOU_FORM_TITLE',
    type: 'text',
    required: true,
    component: Select,
    placeholder: 'Please select',
    options: [
      { label: 'Mr', value: 'Mr' },
      { label: 'Mrs', value: 'Mrs' },
      { label: 'Miss', value: 'Miss' },
      { label: 'Ms', value: 'Ms' },
      { label: 'Dr', value: 'Dr' },
      { label: 'Sir', value: 'Sir' },
      { label: 'Prof', value: 'Prof' },
      { label: 'Mx', value: 'Mx' },
      { label: 'Prefer not to say', value: 'Prefer not to say' }
    ],
    autoComplete: 'honorific-prefix'
  }

  const firstNameField = {
    name: 'firstName',
    label: 'ABOUT_YOU_FORM_FIRST_NAME',
    type: 'text',
    required: true,
    hidePlaceholder: true,
    autoComplete: 'given-name'
  }

  const lastNameField = {
    name: 'lastName',
    label: 'ABOUT_YOU_FORM_LAST_NAME',
    type: 'text',
    required: true,
    hidePlaceholder: true,
    autoComplete: 'family-name'
  }

  const disabledEmailField = {
    name: 'email',
    label: 'ABOUT_YOU_FORM_EMAIL',
    type: 'email',
    required: true,
    hidePlaceholder: true,
    disabled: true
  }

  const emailField = {
    name: 'email',
    label: 'ABOUT_YOU_FORM_EMAIL',
    type: 'email',
    required: true,
    hidePlaceholder: true,
    autoComplete: 'email'
  }

  const termsAndConditionsField = {
    name: 'privacy',
    label: 'SIGN_UP_TERMS_AND_CONDITIONS',
    required: true,
    hideLabel: true,
    component: Checkbox,
    legend: 'SIGN_UP_TERMS_AND_CONDITIONS_LEGEND',
    labelLink: '/privacy-policy',
    keyQuestion: true
  }

  const privacyField = {
    name: 'privacy',
    label: 'ABOUT_YOU_FORM_PRIVACY',
    required: true,
    hideLabel: true,
    component: Checkbox,
    legend: 'ABOUT_YOU_FORM_PRIVACY_LEGEND',
    labelLink: '/privacy-policy',
    keyQuestion: true
  }

  const smsPermittedField = {
    name: 'smsCommunicationsPermitted',
    label: 'ABOUT_YOU_SMS_PERMITTED',
    required: false,
    hideLabel: true,
    component: Checkbox,
    isVisible: (values) => !_.isEmpty(values.telephone),
    initialValue: _.get(initialValues, 'smsCommunicationsPermitted')
  }

  const emailPermittedField = {
    name: 'emailCommunicationsPermitted',
    label: 'ABOUT_YOU_EMAIL_PERMITTED',
    required: false,
    hideLabel: true,
    component: Checkbox,
    legend: 'ABOUT_YOU_COMMUNICATION_PREFERENCES',
    initialValue: _.get(initialValues, 'emailCommunicationsPermitted')
  }

  const submitWithoutRegistering = {
    name: 'submitWithoutRegistering',
    component: SubmitButton,
    type: 'button',
    label: 'ABOUT_YOU_FORM_SUBMIT_WITHOUT_REGISTERING'
  }

  const passwordField = {
    name: 'password',
    label: 'ABOUT_YOU_FORM_SET_PASSWORD',
    component: Password,
    helpId: 'passwordHelp',
    autoComplete: 'new-password'
  }

  const confirmpasswordField = {
    name: 'confirmpassword',
    label: 'ABOUT_YOU_FORM_CONFIRM_PASSWORD',
    required: true,
    component: Password,
    isVisible: (values) => !!values.password
  }

  const currentPasswordField = {
    name: 'currentPassword',
    required: true,
    label: 'INPUT_CURRENT_PASSWORD',
    component: Password,
    helpId: 'passwordHelp',
    autoComplete: 'current-password'
  }

  const registerPasswordField = {
    name: 'password',
    required: true,
    label: 'ABOUT_YOU_FORM_SET_PASSWORD',
    component: Password,
    helpId: 'passwordHelp',
    autoComplete: 'new-password'
  }

  const registerPasswordConfirmField = {
    name: 'confirmpassword',
    label: 'ABOUT_YOU_FORM_CONFIRM_PASSWORD',
    required: true,
    component: Password
  }

  const updatePasswordField = {
    name: 'password',
    required: true,
    label: 'UPDATE_PASSWORD_FORM_SET_PASSWORD',
    component: Password,
    helpId: 'passwordHelp',
    autoComplete: 'current-password'
  }

  const updatePasswordConfirmField = {
    name: 'confirmpassword',
    label: 'UPDATE_PASSWORD_FORM_CONFIRM_PASSWORD',
    required: true,
    component: Password
  }

  const passwordInfo = {
    label: 'ABOUT_YOU_FORM_PASSWORD_REQUIREMENTS_HEADING',
    helpText: 'ABOUT_YOU_FORM_PASSWORD_REQUIREMENTS',
    component: InfoList,
    id: 'passwordHelp'
  }

  const registerHeading = {
    label: 'ABOUT_YOU_REGISTER_HEADING',
    helpText: 'ABOUT_YOU_FORM_REGISTER_HELP',
    component: FormHeading,
    headingTag: 'h2'
  }

  const updatePasswordHeading = {
    label: 'UPDATE_PASSWORD_HEADING',
    helpText: 'PASSWORD_CHANGE_LOGOUT_WARNING',
    component: FormHeading,
    headingTag: 'h2'
  }

  const houseNameField = {
    name: 'houseNumberOrName',
    label: 'ACCOUNT_FORM_HOUSE_NAME',
    type: 'text',
    required: false,
    hidePlaceholder: true,
    isVisible: (values) => values.occupation === 'public'
  }

  const orgNameField = {
    name: 'houseNumberOrName',
    label: 'ACCOUNT_FORM_ORG_NAME',
    type: 'text',
    required: false,
    hidePlaceholder: true,
    isVisible: (values) => values.occupation === 'hcp',
    autoComplete: 'organization'
  }

  const addressLineOneField = {
    name: 'address',
    label: 'ACCOUNT_FORM_ADDRESS_LINE_1',
    type: 'text',
    required: false,
    hidePlaceholder: true,
    autoComplete: 'address-line1'
  }

  const addressLineTwoField = {
    name: 'addressLineTwo',
    label: 'ACCOUNT_FORM_ADDRESS_LINE_2',
    type: 'text',
    required: false,
    hidePlaceholder: true,
    autoComplete: 'address-line2'
  }

  const cityField = {
    name: 'city',
    label: 'ACCOUNT_FORM_CITY',
    type: 'text',
    required: false,
    hidePlaceholder: true,
    autoComplete: 'address-level1'
  }

  const countyField = {
    name: 'county',
    label: 'ACCOUNT_FORM_COUNTY',
    type: 'text',
    required: false,
    hidePlaceholder: true,
    autoComplete: 'address-level2'
  }

  const postcodeField = {
    name: 'postalCode',
    label: 'ACCOUNT_FORM_POSTCODE',
    type: 'text',
    required: true,
    hidePlaceholder: true,
    autoComplete: 'postal-code'
  }

  const telephoneField = {
    name: 'telephone',
    label: 'ACCOUNT_FORM_TELEPHONE',
    type: 'text',
    required: false,
    hidePlaceholder: true,
    autoComplete: 'tel'
  }

  const telephoneExtensionField = {
    name: 'telephoneExtension',
    label: 'ACCOUNT_FORM_TELEPHONE_EXTENSION',
    type: 'text',
    required: false,
    hidePlaceholder: true,
    autoComplete: 'tel-extension'
  }

  const telephoneCountryCodeField = {
    name: 'telephoneCountryCode',
    label: 'ACCOUNT_FORM_TELEPHONE_COUNTRY_CODE',
    type: 'text',
    component: Select,
    placeholder: 'Please select',
    options: countryCodeOptions
  }

  const defaultFieldSchema = [
    occupationField,
    professionField,
    publicProfessionField,
    titleField,
    firstNameField,
    lastNameField,
    emailField,
    houseNameField,
    orgNameField,
    addressLineOneField,
    addressLineTwoField,
    cityField,
    countyField,
    postcodeField,
    telephoneCountryCodeField,
    telephoneField,
    telephoneExtensionField,
    emailPermittedField,
    smsPermittedField,
    privacyField,
    submitWithoutRegistering,
    registerHeading,
    passwordField,
    confirmpasswordField,
    passwordInfo
  ]

  const registerFieldSchema = [
    occupationField,
    professionField,
    publicProfessionField,
    titleField,
    firstNameField,
    lastNameField,
    emailField,
    houseNameField,
    orgNameField,
    addressLineOneField,
    addressLineTwoField,
    cityField,
    countyField,
    postcodeField,
    telephoneCountryCodeField,
    telephoneField,
    telephoneExtensionField,
    emailPermittedField,
    smsPermittedField,
    registerPasswordField,
    registerPasswordConfirmField,
    passwordInfo,
    termsAndConditionsField
  ]

  const accountFieldSchema = [
    occupationField,
    professionField,
    publicProfessionField,
    titleField,
    firstNameField,
    lastNameField,
    disabledEmailField,
    houseNameField,
    orgNameField,
    addressLineOneField,
    addressLineTwoField,
    cityField,
    countyField,
    postcodeField,
    telephoneCountryCodeField,
    telephoneField,
    telephoneExtensionField,
    emailPermittedField,
    smsPermittedField
  ]

  const adminAccountFieldSchema = [
    titleField,
    firstNameField,
    lastNameField,
    disabledEmailField,
    addressLineOneField,
    addressLineTwoField,
    cityField,
    countyField,
    postcodeField,
    telephoneCountryCodeField,
    telephoneField,
    telephoneExtensionField,
    emailPermittedField,
    smsPermittedField
  ]

  const updatePasswordSchema = [
    updatePasswordHeading,
    currentPasswordField,
    updatePasswordField,
    updatePasswordConfirmField,
    passwordInfo
  ]

  if (variant === 'register') {
    return registerFieldSchema
  }
  if (variant === 'account') {
    return accountFieldSchema
  }
  if (variant === 'updatePassword') {
    return updatePasswordSchema
  }
  if (variant === 'adminAccount') {
    return adminAccountFieldSchema
  }
  return defaultFieldSchema
}

const UserRegisterForm = (props) => {
  const {
    onSubmit,
    variant,
    parseError,
    fieldOptions = {},
    initialValues = {},
    successMessage,
    buttonLabel,
    enableReinitialize
  } = props
  const translate = useTranslate()
  const translateExists = useTranslate('exists')
  const prefillOccupationValue = useShouldPrefillOccupationValue()

  const handleSubmit = React.useCallback((values) => {
    const determineProfessionId = (occupation) => {
      const selectedHCP = _.get(values, 'hcpProfessionId')
      const singleHCP = _.get(fieldOptions, 'profession.0.value')
      const selectedPublic = _.get(values, 'publicProfessionId')
      const singlePublic = _.get(fieldOptions, 'publicprofession.0.value')
      let professionId
      switch (occupation) {
        case 'hcp':
          professionId = selectedHCP || singleHCP
          break
        case 'public':
          professionId = selectedPublic || singlePublic
          break
        default:
          break
      }
      return professionId
    }
    const newValues = {
      ...values,
      professionId: determineProfessionId(values.occupation)
    }
    if (onSubmit) {
      return onSubmit(newValues)
    }
  }, [fieldOptions, prefillOccupationValue])

  const formSchema = getSchema({ variant, fieldOptions, prefillOccupationValue, reportId: props.reportId, translate, translateExists, initialValues })
  const validationSchema = getValidationSchema({ variant, fieldOptions, prefillOccupationValue })

  const formProps = {
    onSubmit: handleSubmit,
    buttonLabel: buttonLabel || translate('BUTTON_LABEL_SUBMIT'),
    fieldSchema: formSchema,
    validationSchema,
    parseError,
    initialValues: getInitialValues({ variant, initialValues }),
    successMessage,
    enableReinitialize
  }
  return (
    <Form {...formProps} />
  )
}

export default UserRegisterForm
