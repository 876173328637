import styled from 'styled-components'

import FieldBase from './common/FieldBase'
import InputBase from './common/InputBase'
import Icon from 'components/Icon'
import VisuallyHidden from '@reach/visually-hidden'

import useTranslate from 'hooks/useTranslate'

import * as colors from '../../config/theme/colors'
import { iconSize } from './common/vars'

const HideShowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: transparent;
  border: 0;
  margin-right: 5px;
  padding: 0;
  cursor: pointer;

  &:hover, &:active, &:focus {
    background-color: ${colors.buttonHoverColorSecondary};
  }
`

const ShowHidePasswordButton = (props) => {
  const translate = useTranslate()
  return (
    <HideShowButton {...props} aria-pressed={props.inputtype !== 'password'}>
      <VisuallyHidden>
        {props.inputtype === 'password'
          ? translate('BUTTON_LABEL_PASSWORD_SHOW')
          : translate('BUTTON_LABEL_PASSWORD_HIDE')}
      </VisuallyHidden>
      <Icon
        name={props.inputtype === 'password' ? 'eye' : 'hideEye'}
        size={iconSize}
        width={38}
        marginLeft={8}
        as={'span'}
      />
    </HideShowButton>
  )
}

const renderField = (props) => {
  const [type, setType] = React.useState('password')

  return (
    <InputBase
      {...props}
      inlineRight={{
        component: ShowHidePasswordButton,
        props: {
          type: 'button',
          onClick: () => setType(type === 'password' ? 'text' : 'password'),
          inputtype: type
        }
      }}
      type={type}
    />
  )
}

const Text = (props) => {
  return <FieldBase {...props} renderField={renderField} />
}

export default Text
