import FieldBase from './common/FieldBase'
import useTranslate from 'hooks/useTranslate'
import Button from 'components/Button'
import styled from 'styled-components'

const SubmitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 25px;
`
const StyledSubmitButton = styled(Button)`
  width: auto;
`

const renderField = (props) => {
  const translate = useTranslate()
  const { label } = props
  return (
    <SubmitWrapper>
      <StyledSubmitButton type='submit'>{translate(label)}</StyledSubmitButton>
    </SubmitWrapper>
  )
}

const SubmitButton = (props) => {
  return <FieldBase {...props} renderField={renderField} hideLabel noLabel />
}

export default SubmitButton
