import styled from 'styled-components'
import {
  fieldMarginBottom,
  fieldMarginBottomMobile
} from './common/vars'

import * as colors from 'config/theme/colors'
import media from 'config/theme/media'

const InfoListWrapper = styled.div`
  width: 100%;
  background-color: ${colors.grey()};
  padding: 15px;
  margin-bottom: ${fieldMarginBottomMobile}px;
  ${media.greaterThan('md')`
    padding: 24px;
    margin-bottom: ${fieldMarginBottom}px;
  `}
`

const Heading = styled.p`
  margin: 0 0 8px 0;
  line-height: 24px;
`

const InfoList = styled.ul`
  margin: 0;
`

const InfoListItem = styled.li`
  margin: 0 0 8px 0;
  padding: 0;
  line-height: 20px;
  &:last-child {
    margin: 0;
  }
`

const FormInfoList = (props) => {
  const { id, label, helpText } = props
  const infoList = helpText.split(',').map(requirement => {
    return requirement.trim()
  })

  return (
    <InfoListWrapper id={id}>
      {label && <Heading>{label}</Heading>}
      <InfoList>
        {infoList.map(info => {
          return <InfoListItem key={info}>{info}</InfoListItem>
        })}
      </InfoList>
    </InfoListWrapper>
  )
}

export default FormInfoList
