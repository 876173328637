import styled from 'styled-components'
import {
  fontSize,
  fieldMarginBottom,
  labelFontSize,
  labelLineHeight,
  helpTextTopMargin
} from './common/vars'

import P from '../Typography/P'

const Heading = styled(P)`
  font-size: ${labelFontSize}px;
  font-weight: bold;
  line-height: ${labelLineHeight}px;
  margin: 0;
`

const Sub = styled(P)`
  font-size: ${fontSize}px;
  margin: 0;
  margin-top: ${helpTextTopMargin}px;
`

const Field = styled.div`
  margin-bottom: ${fieldMarginBottom}px;
`

const FormHeading = (props) => {
  const { headingTag = 'h2', label, helpText } = props
  return (
    <Field>
      {<Heading as={headingTag}>{label}</Heading>}
      {helpText && <Sub>{helpText}</Sub>}
    </Field>
  )
}

export default FormHeading
