import { useSelector } from 'react-redux'

import { getCurrentOrganisationProfessions, getPublicProfessionsOptions, getHCPProfessionsOptions, getCurrentOrganisationDetails, shouldShowOccupationDropdown, shouldPrefillOccupationValue } from '../store/modules/organisationDetails/selectors'

/**
 * @description returns initial form values based on logged in user
 * @returns
 */
export const useProfessions = () => {
  return useSelector(getCurrentOrganisationProfessions)
}

export const useHCPProfessions = () => {
  return useSelector(getHCPProfessionsOptions)
}

export const usePublicProfessions = () => {
  return useSelector(getPublicProfessionsOptions)
}

export const useOrganisationDetails = () => {
  return useSelector(getCurrentOrganisationDetails)
}

export const useShouldPrefillOccupationValue = () => {
  return useSelector(shouldPrefillOccupationValue)
}
