import _ from 'lodash'

const codes = {
  AF: {
    code: 'AF',
    unicode: 'U+1F1E6 U+1F1EB',
    name: 'Afghanistan',
    emoji: '🇦🇫',
    dialCode: '+93'
  },
  AX: {
    code: 'AX',
    unicode: 'U+1F1E6 U+1F1FD',
    name: 'Åland Islands',
    emoji: '🇦🇽',
    dialCode: '+358'
  },
  AL: {
    code: 'AL',
    unicode: 'U+1F1E6 U+1F1F1',
    name: 'Albania',
    emoji: '🇦🇱',
    dialCode: '+355'
  },
  DZ: {
    code: 'DZ',
    unicode: 'U+1F1E9 U+1F1FF',
    name: 'Algeria',
    emoji: '🇩🇿',
    dialCode: '+213'
  },
  AS: {
    code: 'AS',
    unicode: 'U+1F1E6 U+1F1F8',
    name: 'American Samoa',
    emoji: '🇦🇸',
    dialCode: '+1684'
  },
  AD: {
    code: 'AD',
    unicode: 'U+1F1E6 U+1F1E9',
    name: 'Andorra',
    emoji: '🇦🇩',
    dialCode: '+376'
  },
  AO: {
    code: 'AO',
    unicode: 'U+1F1E6 U+1F1F4',
    name: 'Angola',
    emoji: '🇦🇴',
    dialCode: '+244'
  },
  AI: {
    code: 'AI',
    unicode: 'U+1F1E6 U+1F1EE',
    name: 'Anguilla',
    emoji: '🇦🇮',
    dialCode: '+1264'
  },
  AQ: {
    code: 'AQ',
    unicode: 'U+1F1E6 U+1F1F6',
    name: 'Antarctica',
    emoji: '🇦🇶',
    dialCode: '+672'
  },
  AG: {
    code: 'AG',
    unicode: 'U+1F1E6 U+1F1EC',
    name: 'Antigua & Barbuda',
    emoji: '🇦🇬',
    dialCode: '+1268'
  },
  AR: {
    code: 'AR',
    unicode: 'U+1F1E6 U+1F1F7',
    name: 'Argentina',
    emoji: '🇦🇷',
    dialCode: '+54'
  },
  AM: {
    code: 'AM',
    unicode: 'U+1F1E6 U+1F1F2',
    name: 'Armenia',
    emoji: '🇦🇲',
    dialCode: '+374'
  },
  AW: {
    code: 'AW',
    unicode: 'U+1F1E6 U+1F1FC',
    name: 'Aruba',
    emoji: '🇦🇼',
    dialCode: '+297'
  },
  AC: {
    code: 'AC',
    unicode: 'U+1F1E6 U+1F1E8',
    name: 'Ascension Island',
    emoji: '🇦🇨',
    dialCode: '+247'
  },
  AU: {
    code: 'AU',
    unicode: 'U+1F1E6 U+1F1FA',
    name: 'Australia',
    emoji: '🇦🇺',
    dialCode: '+61'
  },
  AT: {
    code: 'AT',
    unicode: 'U+1F1E6 U+1F1F9',
    name: 'Austria',
    emoji: '🇦🇹',
    dialCode: '+43'
  },
  AZ: {
    code: 'AZ',
    unicode: 'U+1F1E6 U+1F1FF',
    name: 'Azerbaijan',
    emoji: '🇦🇿',
    dialCode: '+994'
  },
  BS: {
    code: 'BS',
    unicode: 'U+1F1E7 U+1F1F8',
    name: 'Bahamas',
    emoji: '🇧🇸',
    dialCode: '+1242'
  },
  BH: {
    code: 'BH',
    unicode: 'U+1F1E7 U+1F1ED',
    name: 'Bahrain',
    emoji: '🇧🇭',
    dialCode: '+973'
  },
  BD: {
    code: 'BD',
    unicode: 'U+1F1E7 U+1F1E9',
    name: 'Bangladesh',
    emoji: '🇧🇩',
    dialCode: '+880'
  },
  BB: {
    code: 'BB',
    unicode: 'U+1F1E7 U+1F1E7',
    name: 'Barbados',
    emoji: '🇧🇧',
    dialCode: '+1246'
  },
  BY: {
    code: 'BY',
    unicode: 'U+1F1E7 U+1F1FE',
    name: 'Belarus',
    emoji: '🇧🇾',
    dialCode: '+375'
  },
  BE: {
    code: 'BE',
    unicode: 'U+1F1E7 U+1F1EA',
    name: 'Belgium',
    emoji: '🇧🇪',
    dialCode: '+32'
  },
  BZ: {
    code: 'BZ',
    unicode: 'U+1F1E7 U+1F1FF',
    name: 'Belize',
    emoji: '🇧🇿',
    dialCode: '+501'
  },
  BJ: {
    code: 'BJ',
    unicode: 'U+1F1E7 U+1F1EF',
    name: 'Benin',
    emoji: '🇧🇯',
    dialCode: '+229'
  },
  BM: {
    code: 'BM',
    unicode: 'U+1F1E7 U+1F1F2',
    name: 'Bermuda',
    emoji: '🇧🇲',
    dialCode: '+1441'
  },
  BT: {
    code: 'BT',
    unicode: 'U+1F1E7 U+1F1F9',
    name: 'Bhutan',
    emoji: '🇧🇹',
    dialCode: '+975'
  },
  BO: {
    code: 'BO',
    unicode: 'U+1F1E7 U+1F1F4',
    name: 'Bolivia',
    emoji: '🇧🇴',
    dialCode: '+591'
  },
  BA: {
    code: 'BA',
    unicode: 'U+1F1E7 U+1F1E6',
    name: 'Bosnia & Herzegovina',
    emoji: '🇧🇦',
    dialCode: '+387'
  },
  BW: {
    code: 'BW',
    unicode: 'U+1F1E7 U+1F1FC',
    name: 'Botswana',
    emoji: '🇧🇼',
    dialCode: '+267'
  },
  BR: {
    code: 'BR',
    unicode: 'U+1F1E7 U+1F1F7',
    name: 'Brazil',
    emoji: '🇧🇷',
    dialCode: '+55'
  },
  IO: {
    code: 'IO',
    unicode: 'U+1F1EE U+1F1F4',
    name: 'British Indian Ocean Territory',
    emoji: '🇮🇴',
    dialCode: '+246'
  },
  BN: {
    code: 'BN',
    unicode: 'U+1F1E7 U+1F1F3',
    name: 'Brunei',
    emoji: '🇧🇳',
    dialCode: '+673'
  },
  BG: {
    code: 'BG',
    unicode: 'U+1F1E7 U+1F1EC',
    name: 'Bulgaria',
    emoji: '🇧🇬',
    dialCode: '+359'
  },
  BF: {
    code: 'BF',
    unicode: 'U+1F1E7 U+1F1EB',
    name: 'Burkina Faso',
    emoji: '🇧🇫',
    dialCode: '+226'
  },
  BI: {
    code: 'BI',
    unicode: 'U+1F1E7 U+1F1EE',
    name: 'Burundi',
    emoji: '🇧🇮',
    dialCode: '+257'
  },
  KH: {
    code: 'KH',
    unicode: 'U+1F1F0 U+1F1ED',
    name: 'Cambodia',
    emoji: '🇰🇭',
    dialCode: '+855'
  },
  CM: {
    code: 'CM',
    unicode: 'U+1F1E8 U+1F1F2',
    name: 'Cameroon',
    emoji: '🇨🇲',
    dialCode: '+237'
  },
  CA: {
    code: 'CA',
    unicode: 'U+1F1E8 U+1F1E6',
    name: 'Canada',
    emoji: '🇨🇦',
    dialCode: '+1'
  },
  CV: {
    code: 'CV',
    unicode: 'U+1F1E8 U+1F1FB',
    name: 'Cape Verde',
    emoji: '🇨🇻',
    dialCode: '+238'
  },
  KY: {
    code: 'KY',
    unicode: 'U+1F1F0 U+1F1FE',
    name: 'Cayman Islands',
    emoji: '🇰🇾',
    dialCode: '+1345'
  },
  CF: {
    code: 'CF',
    unicode: 'U+1F1E8 U+1F1EB',
    name: 'Central African Republic',
    emoji: '🇨🇫',
    dialCode: '+236'
  },
  TD: {
    code: 'TD',
    unicode: 'U+1F1F9 U+1F1E9',
    name: 'Chad',
    emoji: '🇹🇩',
    dialCode: '+235'
  },
  CL: {
    code: 'CL',
    unicode: 'U+1F1E8 U+1F1F1',
    name: 'Chile',
    emoji: '🇨🇱',
    dialCode: '+56'
  },
  CN: {
    code: 'CN',
    unicode: 'U+1F1E8 U+1F1F3',
    name: 'China',
    emoji: '🇨🇳',
    dialCode: '+86'
  },
  CX: {
    code: 'CX',
    unicode: 'U+1F1E8 U+1F1FD',
    name: 'Christmas Island',
    emoji: '🇨🇽',
    dialCode: '+61'
  },
  CC: {
    code: 'CC',
    unicode: 'U+1F1E8 U+1F1E8',
    name: 'Cocos (Keeling) Islands',
    emoji: '🇨🇨',
    dialCode: '+61'
  },
  CO: {
    code: 'CO',
    unicode: 'U+1F1E8 U+1F1F4',
    name: 'Colombia',
    emoji: '🇨🇴',
    dialCode: '+57'
  },
  KM: {
    code: 'KM',
    unicode: 'U+1F1F0 U+1F1F2',
    name: 'Comoros',
    emoji: '🇰🇲',
    dialCode: '+269'
  },
  CG: {
    code: 'CG',
    unicode: 'U+1F1E8 U+1F1EC',
    name: 'Congo - Brazzaville',
    emoji: '🇨🇬',
    dialCode: '+242'
  },
  CK: {
    code: 'CK',
    unicode: 'U+1F1E8 U+1F1F0',
    name: 'Cook Islands',
    emoji: '🇨🇰',
    dialCode: '+682'
  },
  CR: {
    code: 'CR',
    unicode: 'U+1F1E8 U+1F1F7',
    name: 'Costa Rica',
    emoji: '🇨🇷',
    dialCode: '+506'
  },
  HR: {
    code: 'HR',
    unicode: 'U+1F1ED U+1F1F7',
    name: 'Croatia',
    emoji: '🇭🇷',
    dialCode: '+385'
  },
  CU: {
    code: 'CU',
    unicode: 'U+1F1E8 U+1F1FA',
    name: 'Cuba',
    emoji: '🇨🇺',
    dialCode: '+53'
  },
  CY: {
    code: 'CY',
    unicode: 'U+1F1E8 U+1F1FE',
    name: 'Cyprus',
    emoji: '🇨🇾',
    dialCode: '+357'
  },
  CZ: {
    code: 'CZ',
    unicode: 'U+1F1E8 U+1F1FF',
    name: 'Czechia',
    emoji: '🇨🇿',
    dialCode: '+420'
  },
  CD: {
    code: 'CD',
    unicode: 'U+1F1E8 U+1F1E9',
    name: 'Congo - Kinshasa',
    emoji: '🇨🇩',
    dialCode: '+243'
  },
  DK: {
    code: 'DK',
    unicode: 'U+1F1E9 U+1F1F0',
    name: 'Denmark',
    emoji: '🇩🇰',
    dialCode: '+45'
  },
  DJ: {
    code: 'DJ',
    unicode: 'U+1F1E9 U+1F1EF',
    name: 'Djibouti',
    emoji: '🇩🇯',
    dialCode: '+253'
  },
  DM: {
    code: 'DM',
    unicode: 'U+1F1E9 U+1F1F2',
    name: 'Dominica',
    emoji: '🇩🇲',
    dialCode: '+1767'
  },
  DO: {
    code: 'DO',
    unicode: 'U+1F1E9 U+1F1F4',
    name: 'Dominican Republic',
    emoji: '🇩🇴',
    dialCode: '+1849'
  },
  EC: {
    code: 'EC',
    unicode: 'U+1F1EA U+1F1E8',
    name: 'Ecuador',
    emoji: '🇪🇨',
    dialCode: '+593'
  },
  EG: {
    code: 'EG',
    unicode: 'U+1F1EA U+1F1EC',
    name: 'Egypt',
    emoji: '🇪🇬',
    dialCode: '+20'
  },
  SV: {
    code: 'SV',
    unicode: 'U+1F1F8 U+1F1FB',
    name: 'El Salvador',
    emoji: '🇸🇻',
    dialCode: '+503'
  },
  GQ: {
    code: 'GQ',
    unicode: 'U+1F1EC U+1F1F6',
    name: 'Equatorial Guinea',
    emoji: '🇬🇶',
    dialCode: '+240'
  },
  ER: {
    code: 'ER',
    unicode: 'U+1F1EA U+1F1F7',
    name: 'Eritrea',
    emoji: '🇪🇷',
    dialCode: '+291'
  },
  EE: {
    code: 'EE',
    unicode: 'U+1F1EA U+1F1EA',
    name: 'Estonia',
    emoji: '🇪🇪',
    dialCode: '+372'
  },
  SZ: {
    code: 'SZ',
    unicode: 'U+1F1F8 U+1F1FF',
    name: 'Swaziland',
    emoji: '🇸🇿',
    dialCode: '+268'
  },
  ET: {
    code: 'ET',
    unicode: 'U+1F1EA U+1F1F9',
    name: 'Ethiopia',
    emoji: '🇪🇹',
    dialCode: '+251'
  },
  FK: {
    code: 'FK',
    unicode: 'U+1F1EB U+1F1F0',
    name: 'Falkland Islands',
    emoji: '🇫🇰',
    dialCode: '+500'
  },
  FO: {
    code: 'FO',
    unicode: 'U+1F1EB U+1F1F4',
    name: 'Faroe Islands',
    emoji: '🇫🇴',
    dialCode: '+298'
  },
  FJ: {
    code: 'FJ',
    unicode: 'U+1F1EB U+1F1EF',
    name: 'Fiji',
    emoji: '🇫🇯',
    dialCode: '+679'
  },
  FI: {
    code: 'FI',
    unicode: 'U+1F1EB U+1F1EE',
    name: 'Finland',
    emoji: '🇫🇮',
    dialCode: '+358'
  },
  FR: {
    code: 'FR',
    unicode: 'U+1F1EB U+1F1F7',
    name: 'France',
    emoji: '🇫🇷',
    dialCode: '+33'
  },
  GF: {
    code: 'GF',
    unicode: 'U+1F1EC U+1F1EB',
    name: 'French Guiana',
    emoji: '🇬🇫',
    dialCode: '+594'
  },
  PF: {
    code: 'PF',
    unicode: 'U+1F1F5 U+1F1EB',
    name: 'French Polynesia',
    emoji: '🇵🇫',
    dialCode: '+689'
  },
  GA: {
    code: 'GA',
    unicode: 'U+1F1EC U+1F1E6',
    name: 'Gabon',
    emoji: '🇬🇦',
    dialCode: '+241'
  },
  GM: {
    code: 'GM',
    unicode: 'U+1F1EC U+1F1F2',
    name: 'Gambia',
    emoji: '🇬🇲',
    dialCode: '+220'
  },
  GE: {
    code: 'GE',
    unicode: 'U+1F1EC U+1F1EA',
    name: 'Georgia',
    emoji: '🇬🇪',
    dialCode: '+995'
  },
  DE: {
    code: 'DE',
    unicode: 'U+1F1E9 U+1F1EA',
    name: 'Germany',
    emoji: '🇩🇪',
    dialCode: '+49'
  },
  GH: {
    code: 'GH',
    unicode: 'U+1F1EC U+1F1ED',
    name: 'Ghana',
    emoji: '🇬🇭',
    dialCode: '+233'
  },
  GI: {
    code: 'GI',
    unicode: 'U+1F1EC U+1F1EE',
    name: 'Gibraltar',
    emoji: '🇬🇮',
    dialCode: '+350'
  },
  GR: {
    code: 'GR',
    unicode: 'U+1F1EC U+1F1F7',
    name: 'Greece',
    emoji: '🇬🇷',
    dialCode: '+30'
  },
  GL: {
    code: 'GL',
    unicode: 'U+1F1EC U+1F1F1',
    name: 'Greenland',
    emoji: '🇬🇱',
    dialCode: '+299'
  },
  GD: {
    code: 'GD',
    unicode: 'U+1F1EC U+1F1E9',
    name: 'Grenada',
    emoji: '🇬🇩',
    dialCode: '+1473'
  },
  GP: {
    code: 'GP',
    unicode: 'U+1F1EC U+1F1F5',
    name: 'Guadeloupe',
    emoji: '🇬🇵',
    dialCode: '+590'
  },
  GU: {
    code: 'GU',
    unicode: 'U+1F1EC U+1F1FA',
    name: 'Guam',
    emoji: '🇬🇺',
    dialCode: '+1671'
  },
  GT: {
    code: 'GT',
    unicode: 'U+1F1EC U+1F1F9',
    name: 'Guatemala',
    emoji: '🇬🇹',
    dialCode: '+502'
  },
  GG: {
    code: 'GG',
    unicode: 'U+1F1EC U+1F1EC',
    name: 'Guernsey',
    emoji: '🇬🇬',
    dialCode: '+44'
  },
  GN: {
    code: 'GN',
    unicode: 'U+1F1EC U+1F1F3',
    name: 'Guinea',
    emoji: '🇬🇳',
    dialCode: '+224'
  },
  GW: {
    code: 'GW',
    unicode: 'U+1F1EC U+1F1FC',
    name: 'Guinea-Bissau',
    emoji: '🇬🇼',
    dialCode: '+245'
  },
  GY: {
    code: 'GY',
    unicode: 'U+1F1EC U+1F1FE',
    name: 'Guyana',
    emoji: '🇬🇾',
    dialCode: '+592'
  },
  HT: {
    code: 'HT',
    unicode: 'U+1F1ED U+1F1F9',
    name: 'Haiti',
    emoji: '🇭🇹',
    dialCode: '+509'
  },
  VA: {
    code: 'VA',
    unicode: 'U+1F1FB U+1F1E6',
    name: 'Vatican City',
    emoji: '🇻🇦',
    dialCode: '+379'
  },
  HN: {
    code: 'HN',
    unicode: 'U+1F1ED U+1F1F3',
    name: 'Honduras',
    emoji: '🇭🇳',
    dialCode: '+504'
  },
  HK: {
    code: 'HK',
    unicode: 'U+1F1ED U+1F1F0',
    name: 'Hong Kong SAR China',
    emoji: '🇭🇰',
    dialCode: '+852'
  },
  HU: {
    code: 'HU',
    unicode: 'U+1F1ED U+1F1FA',
    name: 'Hungary',
    emoji: '🇭🇺',
    dialCode: '+36'
  },
  IS: {
    code: 'IS',
    unicode: 'U+1F1EE U+1F1F8',
    name: 'Iceland',
    emoji: '🇮🇸',
    dialCode: '+354'
  },
  IN: {
    code: 'IN',
    unicode: 'U+1F1EE U+1F1F3',
    name: 'India',
    emoji: '🇮🇳',
    dialCode: '+91'
  },
  ID: {
    code: 'ID',
    unicode: 'U+1F1EE U+1F1E9',
    name: 'Indonesia',
    emoji: '🇮🇩',
    dialCode: '+62'
  },
  IR: {
    code: 'IR',
    unicode: 'U+1F1EE U+1F1F7',
    name: 'Iran',
    emoji: '🇮🇷',
    dialCode: '+98'
  },
  IQ: {
    code: 'IQ',
    unicode: 'U+1F1EE U+1F1F6',
    name: 'Iraq',
    emoji: '🇮🇶',
    dialCode: '+964'
  },
  IE: {
    code: 'IE',
    unicode: 'U+1F1EE U+1F1EA',
    name: 'Ireland',
    emoji: '🇮🇪',
    dialCode: '+353'
  },
  IM: {
    code: 'IM',
    unicode: 'U+1F1EE U+1F1F2',
    name: 'Isle of Man',
    emoji: '🇮🇲',
    dialCode: '+44'
  },
  IL: {
    code: 'IL',
    unicode: 'U+1F1EE U+1F1F1',
    name: 'Israel',
    emoji: '🇮🇱',
    dialCode: '+972'
  },
  IT: {
    code: 'IT',
    unicode: 'U+1F1EE U+1F1F9',
    name: 'Italy',
    emoji: '🇮🇹',
    dialCode: '+39'
  },
  CI: {
    code: 'CI',
    unicode: 'U+1F1E8 U+1F1EE',
    name: 'Côte d’Ivoire',
    emoji: '🇨🇮',
    dialCode: '+225'
  },
  JM: {
    code: 'JM',
    unicode: 'U+1F1EF U+1F1F2',
    name: 'Jamaica',
    emoji: '🇯🇲',
    dialCode: '+1876'
  },
  JP: {
    code: 'JP',
    unicode: 'U+1F1EF U+1F1F5',
    name: 'Japan',
    emoji: '🇯🇵',
    dialCode: '+81'
  },
  JE: {
    code: 'JE',
    unicode: 'U+1F1EF U+1F1EA',
    name: 'Jersey',
    emoji: '🇯🇪',
    dialCode: '+44'
  },
  JO: {
    code: 'JO',
    unicode: 'U+1F1EF U+1F1F4',
    name: 'Jordan',
    emoji: '🇯🇴',
    dialCode: '+962'
  },
  KZ: {
    code: 'KZ',
    unicode: 'U+1F1F0 U+1F1FF',
    name: 'Kazakhstan',
    emoji: '🇰🇿',
    dialCode: '+77'
  },
  KE: {
    code: 'KE',
    unicode: 'U+1F1F0 U+1F1EA',
    name: 'Kenya',
    emoji: '🇰🇪',
    dialCode: '+254'
  },
  KI: {
    code: 'KI',
    unicode: 'U+1F1F0 U+1F1EE',
    name: 'Kiribati',
    emoji: '🇰🇮',
    dialCode: '+686'
  },
  KP: {
    code: 'KP',
    unicode: 'U+1F1F0 U+1F1F5',
    name: 'North Korea',
    emoji: '🇰🇵',
    dialCode: '+850'
  },
  KR: {
    code: 'KR',
    unicode: 'U+1F1F0 U+1F1F7',
    name: 'South Korea',
    emoji: '🇰🇷',
    dialCode: '+82'
  },
  XK: {
    code: 'XK',
    unicode: 'U+1F1FD U+1F1F0',
    name: 'Kosovo',
    emoji: '🇽🇰',
    dialCode: '+383'
  },
  KW: {
    code: 'KW',
    unicode: 'U+1F1F0 U+1F1FC',
    name: 'Kuwait',
    emoji: '🇰🇼',
    dialCode: '+965'
  },
  KG: {
    code: 'KG',
    unicode: 'U+1F1F0 U+1F1EC',
    name: 'Kyrgyzstan',
    emoji: '🇰🇬',
    dialCode: '+996'
  },
  LA: {
    code: 'LA',
    unicode: 'U+1F1F1 U+1F1E6',
    name: 'Laos',
    emoji: '🇱🇦',
    dialCode: '+856'
  },
  LV: {
    code: 'LV',
    unicode: 'U+1F1F1 U+1F1FB',
    name: 'Latvia',
    emoji: '🇱🇻',
    dialCode: '+371'
  },
  LB: {
    code: 'LB',
    unicode: 'U+1F1F1 U+1F1E7',
    name: 'Lebanon',
    emoji: '🇱🇧',
    dialCode: '+961'
  },
  LS: {
    code: 'LS',
    unicode: 'U+1F1F1 U+1F1F8',
    name: 'Lesotho',
    emoji: '🇱🇸',
    dialCode: '+266'
  },
  LR: {
    code: 'LR',
    unicode: 'U+1F1F1 U+1F1F7',
    name: 'Liberia',
    emoji: '🇱🇷',
    dialCode: '+231'
  },
  LY: {
    code: 'LY',
    unicode: 'U+1F1F1 U+1F1FE',
    name: 'Libya',
    emoji: '🇱🇾',
    dialCode: '+218'
  },
  LI: {
    code: 'LI',
    unicode: 'U+1F1F1 U+1F1EE',
    name: 'Liechtenstein',
    emoji: '🇱🇮',
    dialCode: '+423'
  },
  LT: {
    code: 'LT',
    unicode: 'U+1F1F1 U+1F1F9',
    name: 'Lithuania',
    emoji: '🇱🇹',
    dialCode: '+370'
  },
  LU: {
    code: 'LU',
    unicode: 'U+1F1F1 U+1F1FA',
    name: 'Luxembourg',
    emoji: '🇱🇺',
    dialCode: '+352'
  },
  MO: {
    code: 'MO',
    unicode: 'U+1F1F2 U+1F1F4',
    name: 'Macau SAR China',
    emoji: '🇲🇴',
    dialCode: '+853'
  },
  MG: {
    code: 'MG',
    unicode: 'U+1F1F2 U+1F1EC',
    name: 'Madagascar',
    emoji: '🇲🇬',
    dialCode: '+261'
  },
  MW: {
    code: 'MW',
    unicode: 'U+1F1F2 U+1F1FC',
    name: 'Malawi',
    emoji: '🇲🇼',
    dialCode: '+265'
  },
  MY: {
    code: 'MY',
    unicode: 'U+1F1F2 U+1F1FE',
    name: 'Malaysia',
    emoji: '🇲🇾',
    dialCode: '+60'
  },
  MV: {
    code: 'MV',
    unicode: 'U+1F1F2 U+1F1FB',
    name: 'Maldives',
    emoji: '🇲🇻',
    dialCode: '+960'
  },
  ML: {
    code: 'ML',
    unicode: 'U+1F1F2 U+1F1F1',
    name: 'Mali',
    emoji: '🇲🇱',
    dialCode: '+223'
  },
  MT: {
    code: 'MT',
    unicode: 'U+1F1F2 U+1F1F9',
    name: 'Malta',
    emoji: '🇲🇹',
    dialCode: '+356'
  },
  MH: {
    code: 'MH',
    unicode: 'U+1F1F2 U+1F1ED',
    name: 'Marshall Islands',
    emoji: '🇲🇭',
    dialCode: '+692'
  },
  MQ: {
    code: 'MQ',
    unicode: 'U+1F1F2 U+1F1F6',
    name: 'Martinique',
    emoji: '🇲🇶',
    dialCode: '+596'
  },
  MR: {
    code: 'MR',
    unicode: 'U+1F1F2 U+1F1F7',
    name: 'Mauritania',
    emoji: '🇲🇷',
    dialCode: '+222'
  },
  MU: {
    code: 'MU',
    unicode: 'U+1F1F2 U+1F1FA',
    name: 'Mauritius',
    emoji: '🇲🇺',
    dialCode: '+230'
  },
  YT: {
    code: 'YT',
    unicode: 'U+1F1FE U+1F1F9',
    name: 'Mayotte',
    emoji: '🇾🇹',
    dialCode: '+262'
  },
  MX: {
    code: 'MX',
    unicode: 'U+1F1F2 U+1F1FD',
    name: 'Mexico',
    emoji: '🇲🇽',
    dialCode: '+52'
  },
  FM: {
    code: 'FM',
    unicode: 'U+1F1EB U+1F1F2',
    name: 'Micronesia',
    emoji: '🇫🇲',
    dialCode: '+691'
  },
  MD: {
    code: 'MD',
    unicode: 'U+1F1F2 U+1F1E9',
    name: 'Moldova',
    emoji: '🇲🇩',
    dialCode: '+373'
  },
  MC: {
    code: 'MC',
    unicode: 'U+1F1F2 U+1F1E8',
    name: 'Monaco',
    emoji: '🇲🇨',
    dialCode: '+377'
  },
  MN: {
    code: 'MN',
    unicode: 'U+1F1F2 U+1F1F3',
    name: 'Mongolia',
    emoji: '🇲🇳',
    dialCode: '+976'
  },
  ME: {
    code: 'ME',
    unicode: 'U+1F1F2 U+1F1EA',
    name: 'Montenegro',
    emoji: '🇲🇪',
    dialCode: '+382'
  },
  MS: {
    code: 'MS',
    unicode: 'U+1F1F2 U+1F1F8',
    name: 'Montserrat',
    emoji: '🇲🇸',
    dialCode: '+1664'
  },
  MA: {
    code: 'MA',
    unicode: 'U+1F1F2 U+1F1E6',
    name: 'Morocco',
    emoji: '🇲🇦',
    dialCode: '+212'
  },
  MZ: {
    code: 'MZ',
    unicode: 'U+1F1F2 U+1F1FF',
    name: 'Mozambique',
    emoji: '🇲🇿',
    dialCode: '+258'
  },
  MM: {
    code: 'MM',
    unicode: 'U+1F1F2 U+1F1F2',
    name: 'Myanmar (Burma)',
    emoji: '🇲🇲',
    dialCode: '+95'
  },
  NA: {
    code: 'NA',
    unicode: 'U+1F1F3 U+1F1E6',
    name: 'Namibia',
    emoji: '🇳🇦',
    dialCode: '+264'
  },
  NR: {
    code: 'NR',
    unicode: 'U+1F1F3 U+1F1F7',
    name: 'Nauru',
    emoji: '🇳🇷',
    dialCode: '+674'
  },
  NP: {
    code: 'NP',
    unicode: 'U+1F1F3 U+1F1F5',
    name: 'Nepal',
    emoji: '🇳🇵',
    dialCode: '+977'
  },
  NL: {
    code: 'NL',
    unicode: 'U+1F1F3 U+1F1F1',
    name: 'Netherlands',
    emoji: '🇳🇱',
    dialCode: '+31'
  },
  AN: {
    code: 'AN',
    dialCode: '+599',
    name: 'Netherlands Antilles',
    emoji: '🇧🇶'
  },
  NC: {
    code: 'NC',
    unicode: 'U+1F1F3 U+1F1E8',
    name: 'New Caledonia',
    emoji: '🇳🇨',
    dialCode: '+687'
  },
  NZ: {
    code: 'NZ',
    unicode: 'U+1F1F3 U+1F1FF',
    name: 'New Zealand',
    emoji: '🇳🇿',
    dialCode: '+64'
  },
  NI: {
    code: 'NI',
    unicode: 'U+1F1F3 U+1F1EE',
    name: 'Nicaragua',
    emoji: '🇳🇮',
    dialCode: '+505'
  },
  NE: {
    code: 'NE',
    unicode: 'U+1F1F3 U+1F1EA',
    name: 'Niger',
    emoji: '🇳🇪',
    dialCode: '+227'
  },
  NG: {
    code: 'NG',
    unicode: 'U+1F1F3 U+1F1EC',
    name: 'Nigeria',
    emoji: '🇳🇬',
    dialCode: '+234'
  },
  NU: {
    code: 'NU',
    unicode: 'U+1F1F3 U+1F1FA',
    name: 'Niue',
    emoji: '🇳🇺',
    dialCode: '+683'
  },
  NF: {
    code: 'NF',
    unicode: 'U+1F1F3 U+1F1EB',
    name: 'Norfolk Island',
    emoji: '🇳🇫',
    dialCode: '+672'
  },
  MK: {
    code: 'MK',
    unicode: 'U+1F1F2 U+1F1F0',
    name: 'Macedonia',
    emoji: '🇲🇰',
    dialCode: '+389'
  },
  MP: {
    code: 'MP',
    unicode: 'U+1F1F2 U+1F1F5',
    name: 'Northern Mariana Islands',
    emoji: '🇲🇵',
    dialCode: '+1670'
  },
  NO: {
    code: 'NO',
    unicode: 'U+1F1F3 U+1F1F4',
    name: 'Norway',
    emoji: '🇳🇴',
    dialCode: '+47'
  },
  OM: {
    code: 'OM',
    unicode: 'U+1F1F4 U+1F1F2',
    name: 'Oman',
    emoji: '🇴🇲',
    dialCode: '+968'
  },
  PK: {
    code: 'PK',
    unicode: 'U+1F1F5 U+1F1F0',
    name: 'Pakistan',
    emoji: '🇵🇰',
    dialCode: '+92'
  },
  PW: {
    code: 'PW',
    unicode: 'U+1F1F5 U+1F1FC',
    name: 'Palau',
    emoji: '🇵🇼',
    dialCode: '+680'
  },
  PS: {
    code: 'PS',
    unicode: 'U+1F1F5 U+1F1F8',
    name: 'Palestinian Territories',
    emoji: '🇵🇸',
    dialCode: '+970'
  },
  PA: {
    code: 'PA',
    unicode: 'U+1F1F5 U+1F1E6',
    name: 'Panama',
    emoji: '🇵🇦',
    dialCode: '+507'
  },
  PG: {
    code: 'PG',
    unicode: 'U+1F1F5 U+1F1EC',
    name: 'Papua New Guinea',
    emoji: '🇵🇬',
    dialCode: '+675'
  },
  PY: {
    code: 'PY',
    unicode: 'U+1F1F5 U+1F1FE',
    name: 'Paraguay',
    emoji: '🇵🇾',
    dialCode: '+595'
  },
  PE: {
    code: 'PE',
    unicode: 'U+1F1F5 U+1F1EA',
    name: 'Peru',
    emoji: '🇵🇪',
    dialCode: '+51'
  },
  PH: {
    code: 'PH',
    unicode: 'U+1F1F5 U+1F1ED',
    name: 'Philippines',
    emoji: '🇵🇭',
    dialCode: '+63'
  },
  PN: {
    code: 'PN',
    unicode: 'U+1F1F5 U+1F1F3',
    name: 'Pitcairn Islands',
    emoji: '🇵🇳',
    dialCode: '+872'
  },
  PL: {
    code: 'PL',
    unicode: 'U+1F1F5 U+1F1F1',
    name: 'Poland',
    emoji: '🇵🇱',
    dialCode: '+48'
  },
  PT: {
    code: 'PT',
    unicode: 'U+1F1F5 U+1F1F9',
    name: 'Portugal',
    emoji: '🇵🇹',
    dialCode: '+351'
  },
  PR: {
    code: 'PR',
    unicode: 'U+1F1F5 U+1F1F7',
    name: 'Puerto Rico',
    emoji: '🇵🇷',
    dialCode: '+1939'
  },
  QA: {
    code: 'QA',
    unicode: 'U+1F1F6 U+1F1E6',
    name: 'Qatar',
    emoji: '🇶🇦',
    dialCode: '+974'
  },
  RE: {
    code: 'RE',
    unicode: 'U+1F1F7 U+1F1EA',
    name: 'Réunion',
    emoji: '🇷🇪',
    dialCode: '+262'
  },
  RO: {
    code: 'RO',
    unicode: 'U+1F1F7 U+1F1F4',
    name: 'Romania',
    emoji: '🇷🇴',
    dialCode: '+40'
  },
  RU: {
    code: 'RU',
    unicode: 'U+1F1F7 U+1F1FA',
    name: 'Russia',
    emoji: '🇷🇺',
    dialCode: '+7'
  },
  RW: {
    code: 'RW',
    unicode: 'U+1F1F7 U+1F1FC',
    name: 'Rwanda',
    emoji: '🇷🇼',
    dialCode: '+250'
  },
  BL: {
    code: 'BL',
    unicode: 'U+1F1E7 U+1F1F1',
    name: 'St. Barthélemy',
    emoji: '🇧🇱',
    dialCode: '+590'
  },
  SH: {
    code: 'SH',
    unicode: 'U+1F1F8 U+1F1ED',
    name: 'St. Helena',
    emoji: '🇸🇭',
    dialCode: '+290'
  },
  KN: {
    code: 'KN',
    unicode: 'U+1F1F0 U+1F1F3',
    name: 'St. Kitts & Nevis',
    emoji: '🇰🇳',
    dialCode: '+1869'
  },
  LC: {
    code: 'LC',
    unicode: 'U+1F1F1 U+1F1E8',
    name: 'St. Lucia',
    emoji: '🇱🇨',
    dialCode: '+1758'
  },
  MF: {
    code: 'MF',
    unicode: 'U+1F1F2 U+1F1EB',
    name: 'St. Martin',
    emoji: '🇲🇫',
    dialCode: '+590'
  },
  PM: {
    code: 'PM',
    unicode: 'U+1F1F5 U+1F1F2',
    name: 'St. Pierre & Miquelon',
    emoji: '🇵🇲',
    dialCode: '+508'
  },
  VC: {
    code: 'VC',
    unicode: 'U+1F1FB U+1F1E8',
    name: 'St. Vincent & Grenadines',
    emoji: '🇻🇨',
    dialCode: '+1784'
  },
  WS: {
    code: 'WS',
    unicode: 'U+1F1FC U+1F1F8',
    name: 'Samoa',
    emoji: '🇼🇸',
    dialCode: '+685'
  },
  SM: {
    code: 'SM',
    unicode: 'U+1F1F8 U+1F1F2',
    name: 'San Marino',
    emoji: '🇸🇲',
    dialCode: '+378'
  },
  ST: {
    code: 'ST',
    unicode: 'U+1F1F8 U+1F1F9',
    name: 'São Tomé & Príncipe',
    emoji: '🇸🇹',
    dialCode: '+239'
  },
  SA: {
    code: 'SA',
    unicode: 'U+1F1F8 U+1F1E6',
    name: 'Saudi Arabia',
    emoji: '🇸🇦',
    dialCode: '+966'
  },
  SN: {
    code: 'SN',
    unicode: 'U+1F1F8 U+1F1F3',
    name: 'Senegal',
    emoji: '🇸🇳',
    dialCode: '+221'
  },
  RS: {
    code: 'RS',
    unicode: 'U+1F1F7 U+1F1F8',
    name: 'Serbia',
    emoji: '🇷🇸',
    dialCode: '+381'
  },
  SC: {
    code: 'SC',
    unicode: 'U+1F1F8 U+1F1E8',
    name: 'Seychelles',
    emoji: '🇸🇨',
    dialCode: '+248'
  },
  SL: {
    code: 'SL',
    unicode: 'U+1F1F8 U+1F1F1',
    name: 'Sierra Leone',
    emoji: '🇸🇱',
    dialCode: '+232'
  },
  SG: {
    code: 'SG',
    unicode: 'U+1F1F8 U+1F1EC',
    name: 'Singapore',
    emoji: '🇸🇬',
    dialCode: '+65'
  },
  SX: {
    code: 'SX',
    unicode: 'U+1F1F8 U+1F1FD',
    name: 'Sint Maarten',
    emoji: '🇸🇽',
    dialCode: '+1721'
  },
  SK: {
    code: 'SK',
    unicode: 'U+1F1F8 U+1F1F0',
    name: 'Slovakia',
    emoji: '🇸🇰',
    dialCode: '+421'
  },
  SI: {
    code: 'SI',
    unicode: 'U+1F1F8 U+1F1EE',
    name: 'Slovenia',
    emoji: '🇸🇮',
    dialCode: '+386'
  },
  SB: {
    code: 'SB',
    unicode: 'U+1F1F8 U+1F1E7',
    name: 'Solomon Islands',
    emoji: '🇸🇧',
    dialCode: '+677'
  },
  SO: {
    code: 'SO',
    unicode: 'U+1F1F8 U+1F1F4',
    name: 'Somalia',
    emoji: '🇸🇴',
    dialCode: '+252'
  },
  ZA: {
    code: 'ZA',
    unicode: 'U+1F1FF U+1F1E6',
    name: 'South Africa',
    emoji: '🇿🇦',
    dialCode: '+27'
  },
  GS: {
    code: 'GS',
    unicode: 'U+1F1EC U+1F1F8',
    name: 'South Georgia & South Sandwich Islands',
    emoji: '🇬🇸',
    dialCode: '+500'
  },
  SS: {
    code: 'SS',
    unicode: 'U+1F1F8 U+1F1F8',
    name: 'South Sudan',
    emoji: '🇸🇸',
    dialCode: '+211'
  },
  ES: {
    code: 'ES',
    unicode: 'U+1F1EA U+1F1F8',
    name: 'Spain',
    emoji: '🇪🇸',
    dialCode: '+34'
  },
  LK: {
    code: 'LK',
    unicode: 'U+1F1F1 U+1F1F0',
    name: 'Sri Lanka',
    emoji: '🇱🇰',
    dialCode: '+94'
  },
  SD: {
    code: 'SD',
    unicode: 'U+1F1F8 U+1F1E9',
    name: 'Sudan',
    emoji: '🇸🇩',
    dialCode: '+249'
  },
  SR: {
    code: 'SR',
    unicode: 'U+1F1F8 U+1F1F7',
    name: 'Suriname',
    emoji: '🇸🇷',
    dialCode: '+597'
  },
  SJ: {
    code: 'SJ',
    unicode: 'U+1F1F8 U+1F1EF',
    name: 'Svalbard & Jan Mayen',
    emoji: '🇸🇯',
    dialCode: '+47'
  },
  SE: {
    code: 'SE',
    unicode: 'U+1F1F8 U+1F1EA',
    name: 'Sweden',
    emoji: '🇸🇪',
    dialCode: '+46'
  },
  CH: {
    code: 'CH',
    unicode: 'U+1F1E8 U+1F1ED',
    name: 'Switzerland',
    emoji: '🇨🇭',
    dialCode: '+41'
  },
  SY: {
    code: 'SY',
    unicode: 'U+1F1F8 U+1F1FE',
    name: 'Syria',
    emoji: '🇸🇾',
    dialCode: '+963'
  },
  TW: {
    code: 'TW',
    unicode: 'U+1F1F9 U+1F1FC',
    name: 'Taiwan',
    emoji: '🇹🇼',
    dialCode: '+886'
  },
  TJ: {
    code: 'TJ',
    unicode: 'U+1F1F9 U+1F1EF',
    name: 'Tajikistan',
    emoji: '🇹🇯',
    dialCode: '+992'
  },
  TZ: {
    code: 'TZ',
    unicode: 'U+1F1F9 U+1F1FF',
    name: 'Tanzania',
    emoji: '🇹🇿',
    dialCode: '+255'
  },
  TH: {
    code: 'TH',
    unicode: 'U+1F1F9 U+1F1ED',
    name: 'Thailand',
    emoji: '🇹🇭',
    dialCode: '+66'
  },
  TL: {
    code: 'TL',
    unicode: 'U+1F1F9 U+1F1F1',
    name: 'Timor-Leste',
    emoji: '🇹🇱',
    dialCode: '+670'
  },
  TG: {
    code: 'TG',
    unicode: 'U+1F1F9 U+1F1EC',
    name: 'Togo',
    emoji: '🇹🇬',
    dialCode: '+228'
  },
  TK: {
    code: 'TK',
    unicode: 'U+1F1F9 U+1F1F0',
    name: 'Tokelau',
    emoji: '🇹🇰',
    dialCode: '+690'
  },
  TO: {
    code: 'TO',
    unicode: 'U+1F1F9 U+1F1F4',
    name: 'Tonga',
    emoji: '🇹🇴',
    dialCode: '+676'
  },
  TT: {
    code: 'TT',
    unicode: 'U+1F1F9 U+1F1F9',
    name: 'Trinidad & Tobago',
    emoji: '🇹🇹',
    dialCode: '+1868'
  },
  TN: {
    code: 'TN',
    unicode: 'U+1F1F9 U+1F1F3',
    name: 'Tunisia',
    emoji: '🇹🇳',
    dialCode: '+216'
  },
  TR: {
    code: 'TR',
    unicode: 'U+1F1F9 U+1F1F7',
    name: 'Turkey',
    emoji: '🇹🇷',
    dialCode: '+90'
  },
  TM: {
    code: 'TM',
    unicode: 'U+1F1F9 U+1F1F2',
    name: 'Turkmenistan',
    emoji: '🇹🇲',
    dialCode: '+993'
  },
  TC: {
    code: 'TC',
    unicode: 'U+1F1F9 U+1F1E8',
    name: 'Turks & Caicos Islands',
    emoji: '🇹🇨',
    dialCode: '+1649'
  },
  TV: {
    code: 'TV',
    unicode: 'U+1F1F9 U+1F1FB',
    name: 'Tuvalu',
    emoji: '🇹🇻',
    dialCode: '+688'
  },
  UG: {
    code: 'UG',
    unicode: 'U+1F1FA U+1F1EC',
    name: 'Uganda',
    emoji: '🇺🇬',
    dialCode: '+256'
  },
  UA: {
    code: 'UA',
    unicode: 'U+1F1FA U+1F1E6',
    name: 'Ukraine',
    emoji: '🇺🇦',
    dialCode: '+380'
  },
  AE: {
    code: 'AE',
    unicode: 'U+1F1E6 U+1F1EA',
    name: 'United Arab Emirates',
    emoji: '🇦🇪',
    dialCode: '+971'
  },
  GB: {
    code: 'GB',
    unicode: 'U+1F1EC U+1F1E7',
    name: 'United Kingdom',
    emoji: '🇬🇧',
    dialCode: '+44'
  },
  US: {
    code: 'US',
    unicode: 'U+1F1FA U+1F1F8',
    name: 'United States',
    emoji: '🇺🇸',
    dialCode: '+1'
  },
  UY: {
    code: 'UY',
    unicode: 'U+1F1FA U+1F1FE',
    name: 'Uruguay',
    emoji: '🇺🇾',
    dialCode: '+598'
  },
  UZ: {
    code: 'UZ',
    unicode: 'U+1F1FA U+1F1FF',
    name: 'Uzbekistan',
    emoji: '🇺🇿',
    dialCode: '+998'
  },
  VU: {
    code: 'VU',
    unicode: 'U+1F1FB U+1F1FA',
    name: 'Vanuatu',
    emoji: '🇻🇺',
    dialCode: '+678'
  },
  VE: {
    code: 'VE',
    unicode: 'U+1F1FB U+1F1EA',
    name: 'Venezuela',
    emoji: '🇻🇪',
    dialCode: '+58'
  },
  VN: {
    code: 'VN',
    unicode: 'U+1F1FB U+1F1F3',
    name: 'Vietnam',
    emoji: '🇻🇳',
    dialCode: '+84'
  },
  VG: {
    code: 'VG',
    unicode: 'U+1F1FB U+1F1EC',
    name: 'British Virgin Islands',
    emoji: '🇻🇬',
    dialCode: '+1284'
  },
  VI: {
    code: 'VI',
    unicode: 'U+1F1FB U+1F1EE',
    name: 'U.S. Virgin Islands',
    emoji: '🇻🇮',
    dialCode: '+1340'
  },
  WF: {
    code: 'WF',
    unicode: 'U+1F1FC U+1F1EB',
    name: 'Wallis & Futuna',
    emoji: '🇼🇫',
    dialCode: '+681'
  },
  YE: {
    code: 'YE',
    unicode: 'U+1F1FE U+1F1EA',
    name: 'Yemen',
    emoji: '🇾🇪',
    dialCode: '+967'
  },
  ZM: {
    code: 'ZM',
    unicode: 'U+1F1FF U+1F1F2',
    name: 'Zambia',
    emoji: '🇿🇲',
    dialCode: '+260'
  },
  ZW: {
    code: 'ZW',
    unicode: 'U+1F1FF U+1F1FC',
    name: 'Zimbabwe',
    emoji: '🇿🇼',
    dialCode: '+263'
  }
}

export const getMeta = (code) => {
  return codes[code]
}

export const getInitialCode = (countryName) => {
  return _.get(_.filter(codes, ({ name }) => name && name.toUpperCase() === countryName.toUpperCase()), '0.code')
}

export const getCountryList = () => {
  const filteredCodes = _.filter(codes, ({ emoji, name }) => !_.isUndefined(emoji) && !_.isUndefined(name))
  const codeArray = _.map(filteredCodes, ({ dialCode, name, emoji, code }) => ({ value: code, label: `${emoji} ${name} (${dialCode})` }))
  return _.sortBy(codeArray, ['code'])
}

export default { getMeta, getInitialCode, getCountryList }
